document.addEventListener('DOMContentLoaded', function () {
  const tabs = document.querySelectorAll('.js-offer-tab');
  const offers = document.querySelectorAll('.js-offers-offer');
  const tables = document.querySelectorAll('.js-offers-table');
  const detailsBtn = document.querySelectorAll('.js-offer-detail');

  function resetTabs() {
    tabs.forEach(tab => tab.classList.remove('is-active'));
    offers.forEach(offer => offer.classList.remove('is-active'));
    tables.forEach(table => table.classList.remove('is-active'));
  }

  function activateTab(index) {
    resetTabs();
    tabs[index].classList.add('is-active');
    offers[index].classList.add('is-active');
    tables[index].classList.add('is-active');
  }

  detailsBtn.forEach(btn => {
    btn.addEventListener('click', function (event) {
      event.preventDefault();

      setTimeout(() => {
        const targetId = btn.getAttribute('href');
        const targetElement = document.querySelector(targetId);

        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
    });
  });

  tabs.forEach((tab, index) => {
    tab.addEventListener('click', function () {
      activateTab(index);
      tableToggle();
    });
  });

  if (tabs.length > 0) {
    activateTab(0);
  }
});
