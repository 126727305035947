import { throttle } from 'throttle-debounce';
import ProgressSpy from './ProgressSpy';

document.addEventListener('DOMContentLoaded', () => {
  const $element = document.querySelector('.js-progress-spy');
  const $start = document.querySelector('.js-progress-spy-start');
  const $end = document.querySelector('.js-progress-spy-end');

  function getLimits() {
    const startRect = $start.getBoundingClientRect().top;
    const endRect = $end.getBoundingClientRect();

    return {
      start: startRect.top,
      end: endRect.top + endRect.height + window.pageYOffset,
    };
  }

  if ($element && $start && $end) {
    const initalLimits = getLimits();

    const spy = new ProgressSpy($element, initalLimits.start, initalLimits.end);
    spy.mount();

    document.body.classList.add('use-progress-spy');

    window.addEventListener('resize', throttle(250, () => {
      const limits = getLimits();
      spy.update(limits.start, limits.end);
    }));
  }
});
