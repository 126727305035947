/* global google */

function getIcon(style = 'light') {
  return {
    url: `/app/themes/default/assets/images/front/map/pins.svg#${style}`,
    scaledSize: new google.maps.Size(37, 37),
    anchor: new google.maps.Point(18.5, 18.5),
    labelOrigin: new google.maps.Point(18.5, 18.5),
  };
}

export default getIcon;
