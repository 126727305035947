document.addEventListener('DOMContentLoaded', () => {
  const $formMores = document.querySelectorAll('.js-form-more');

  for (let i = 0; i < $formMores.length; i++) {
    const $formMore = $formMores[i];
    const $fields = $formMore.querySelectorAll('.js-form-more-field');
    const $control = $formMore.querySelector('.js-form-more-control');
    const $button = $control.querySelector('.js-form-more-button');
    const fields = [];
    let currentIndex = null;

    for (let j = 0; j < $fields.length; j++) {
      const $field = $fields[j];
      const $input = $field.querySelector('input, select, textarea');
      const field = {
        element: $field,
        input: $input,
        hidden: $field.hidden,
        required: $input.required,
      };

      if (field.hidden === true && currentIndex === null) {
        currentIndex = j;
      }

      if (field.hidden === true && field.required) {
        input.removeAttribute('required');
      }

      fields.push(field);
    }

    $button.addEventListener('click', (e) => {
      const currentField = fields[currentIndex];
      currentField.element.removeAttribute('hidden');
      currentField.hidden = false;

      if (currentField.required) {
        currentField.element.setAttribute('required', '');
      }

      currentIndex += 1;

      if (currentIndex >= fields.length) {
        $control.setAttribute('hidden', '');
      }
    });
  };

});
