export function createPlaceholder(label, key) {
  const $placeholder = document.createElement('div');
  $placeholder.classList.add('u-cookies-placeholder');

  $placeholder.innerHTML = `
    <button class="c-button c-button--primary" type="button" onclick="window.axeptioSDK.requestConsent('${key}')">
      <span class="c-button__label">Accepter les cookies pour ${label}</span>
    </button>
    <p class="u-text-small">Si le module d'acceptation des cookies ne s'affiche pas au clic sur ce bouton, cela peut venir de votre bloqueur de publicité.</p>
  `;

  return $placeholder;
}

export function createYoutubePlaceholder() {
  return createPlaceholder('YouTube', 'youtube');
}

export function createGMapPlaceholder() {
  return createPlaceholder('Google Maps', 'gmaps');
}
