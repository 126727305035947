import extend from './extend';
import 'mdn-polyfills/Element.prototype.matches';
import 'mdn-polyfills/Array.prototype.includes'; // IE11

/**
* TOGGLER
*
* Helps you to create a11y driven toggable (open / close) scripts.
*/
export default class Toggler {
  constructor(elements, settings) {
    const _ = this;

    _.settings = extend(true, {
      // Set it to rue if you want only one item active at a time
      oneAtATime: false,
      // Set it to true if you want that a click outside of current element closes it
      outsideClose: false,
      // Set it to true if you want that escape key closes the current element
      escClose: false,
      // Callbacks methods for more control on wanted behaviors
      callbacks: {
        beforeOpen: () => { },
        afterOpen: () => { },
        beforeClose: () => { },
        afterClose: () => { },
        beforeAttach: () => { },
        afterAttach: () => { },
        beforeDetach: () => { },
        afterDetach: () => { },
      },
    }, settings || {});

    // Colection of items arranged like [content to toggle, togglers]
    _.items = [];
    // Detect non native togglers for adding a11y features
    _.nonNativeTogglers = [];
    // Current ID of open content
    _.currentId = null;
    // Global status, enabled : toggling is activated, disabled : toggling is deactivated
    _.status = 'disabled';

    // Parse received elements
    for (let i = 0; i < elements.length; i++) {
      const item = {
        content: elements[i][0],
        togglers: elements[i][1],
        nonNativeTogglers: [],
      };

      for (let j = 0; j < item.togglers.length; j++) {
        if (!item.togglers[j].matches('button, [href], [tabindex]:not([tabindex="-1"])')) {
          _.nonNativeTogglers.push(item.togglers[j]);
        }
      }

      // Add it a custom id if they don't have one
      // https://gist.github.com/gordonbrander/2230317
      // In addition we always prefix the custom ID by the character i to avoid querySelector
      // issues, see : https://stackoverflow.com/questions/37270787/uncaught-syntaxerror-failed-to-execute-queryselector-on-document
      const id = item.content.hasAttribute('id') ? item.content.getAttribute('id') : `i${Math.random().toString(36).substr(2, 9)}`;

      item.content.setAttribute('id', id);
      item.id = id;

      _.items.push(item);
    }

    _.handleTogglerClick = _.handleTogglerClick.bind(_);
    _.handleNonNativeTogglersKeyup = _.handleNonNativeTogglersKeyup.bind(_);
    _.handleNonNativeTogglersKeydown = _.handleNonNativeTogglersKeydown.bind(_);
    _.handleOutsideClick = _.handleOutsideClick.bind(_);
    _.handleEscKey = _.handleEscKey.bind(_);
  }

  // Activate toggling
  attach() {
    const _ = this;

    _.settings.callbacks.beforeAttach(_);

    for (let i = 0; i < _.items.length; i++) {
      _.items[i].content.setAttribute('aria-hidden', true);
      for (let j = 0; j < _.items[i].togglers.length; j++) {
        _.items[i].togglers[j].setAttribute('aria-controls', _.items[i].id);
        _.items[i].togglers[j].setAttribute('aria-expanded', false);
        _.items[i].togglers[j].addEventListener('click', _.handleTogglerClick);
      }
    }

    if (_.nonNativeTogglers) {
      for (let i = 0; i < _.nonNativeTogglers.length; i++) {
        _.nonNativeTogglers[i].setAttribute('tabindex', '0');
        _.nonNativeTogglers[i].addEventListener('keyup', _.handleNonNativeTogglersKeyup);
      }

      document.addEventListener('keydown', _.handleNonNativeTogglersKeydown);
    }

    if (_.settings.outsideClose) {
      document.addEventListener('click', _.handleOutsideClick);
    }

    if (_.settings.escClose) {
      document.addEventListener('keyup', _.handleEscKey);
    }

    _.status = 'enabled';

    _.settings.callbacks.afterAttach(_);
  }

  // Deactivate toggling
  detach() {
    const _ = this;

    _.settings.callbacks.beforeDetach(_);

    for (let i = 0; i < _.items.length; i++) {
      _.items[i].content.removeAttribute('aria-hidden');
      for (let j = 0; j < _.items[i].togglers.length; j++) {
        _.items[i].togglers[j].removeAttribute('aria-controls');
        _.items[i].togglers[j].removeAttribute('aria-expanded');
        _.items[i].togglers[j].removeEventListener('click', _.handleTogglerClick);
      }
    }

    if (_.nonNativeTogglers) {
      for (let i = 0; i < _.nonNativeTogglers.length; i++) {
        _.nonNativeTogglers[i].removeAttribute('tabindex');
        _.nonNativeTogglers[i].removeEventListener('keyup', _.handleNonNativeTogglersKeyup);
      }

      document.removeEventListener('keydown', _.handleNonNativeTogglersKeydown);
    }

    if (_.settings.outsideClose) {
      document.removeEventListener('click', _.handleOutsideClick);
    }

    if (_.settings.escClose) {
      document.removeEventListener('keyup', _.handleEscKey);
    }

    _.status = 'disabled';

    _.settings.callbacks.afterDetach(_);
  }

  // Open or close
  toggle(id) {
    const _ = this;

    if (document.querySelector(`#${id}`).getAttribute('aria-hidden') === 'true') {
      _.open(id);
    } else {
      _.close(id);
    }
  }

  // Open content
  open(id) {
    const _ = this;

    if (_.settings.oneAtATime && _.currentId) {
      _.close(_.currentId);
    }

    const content = document.querySelector(`#${id}`);
    const togglers = document.querySelectorAll(`[aria-controls="${id}"]`);

    _.settings.callbacks.beforeOpen(content, togglers);

    for (let i = 0; i < togglers.length; i++) {
      togglers[i].setAttribute('aria-expanded', true);
    }
    content.setAttribute('aria-hidden', false);

    _.currentId = id;

    _.settings.callbacks.afterOpen(content, togglers);
  }

  // Close content
  close(id) {
    const _ = this;

    const content = document.querySelector(`#${id}`);
    const togglers = document.querySelectorAll(`[aria-controls="${id}"]`);

    _.settings.callbacks.beforeClose(content, togglers);

    for (let i = 0; i < togglers.length; i++) {
      togglers[i].setAttribute('aria-expanded', false);
    }
    content.setAttribute('aria-hidden', true);

    _.currentId = null;

    _.settings.callbacks.afterClose(content, togglers);
  }

  // Click on toggler
  handleTogglerClick(e) {
    const _ = this;

    e.preventDefault();

    _.toggle(e.currentTarget.getAttribute('aria-controls'));
  }

  // Prevent space key to scroll the page if we are on a non native toggler
  handleNonNativeTogglersKeydown(e) {
    const _ = this;

    if (_.nonNativeTogglers.includes(e.target) && e.which === 32) {
      e.preventDefault();
    }
  }

  // Toggle from a non native toggler
  handleNonNativeTogglersKeyup(e) {
    const _ = this;

    if (e.which === 32 || e.which === 13) {
      e.preventDefault();
      _.toggle(e.target.getAttribute('aria-controls'));
    }
  }

  // Close when clicking outside of current element
  handleOutsideClick(e) {
    const _ = this;

    if (!_.currentId) return;

    let currentItem;

    for (let i = 0; i < _.items.length; i++) {
      if (_.items[i].id === _.currentId) {
        currentItem = _.items[i];
      }
    }

    let clickOnContent = false;
    if (!currentItem.content.contains(e.target)) {
      clickOnContent = true;
    }

    let clickOnToggler = false;
    for (let i = 0; i < currentItem.togglers.length; i++) {
      if (!currentItem.togglers[i].contains(e.target)) {
        clickOnToggler = true;
      }
    }

    if (!clickOnContent && !clickOnToggler) {
      _.close(_.currentId);
    }
  }

  // Close when escape key is pressed
  handleEscKey(e) {
    const _ = this;

    if (_.currentId && e.which === 27) {
      _.close(_.currentId);
    }
  }
}
