import 'mdn-polyfills/Object.entries'; // For Swiper : https://github.com/nolimits4web/swiper/pull/4238
import 'mdn-polyfills/String.prototype.repeat'; // For A11Y
import 'core-js/features/number/is-nan'; // For Navigation
import Swiper, { A11y, Navigation } from 'swiper';

Swiper.use([A11y, Navigation]);

document.addEventListener('DOMContentLoaded', () => {
  const $sliders = document.querySelectorAll('.js-quote-slider');

  for (let i = 0; i < $sliders.length; i++) {
    const $slider = $sliders[i];

    if ($slider.querySelectorAll('.swiper-slide').length > 1) {
      const slider = new Swiper($slider.querySelector('.swiper-container'), {
        a11y: {
          prevSlideMessage: 'Précédent',
          nextSlideMessage: 'Suivant',
        },
        loop: true,
        navigation: {
          nextEl: $slider.querySelector('.swiper-button-next'),
          prevEl: $slider.querySelector('.swiper-button-prev'),
        },
      });
    } else {
      $slider.classList.add('no-slider');
    }
  }
});
