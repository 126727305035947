import Header from './Header';

document.addEventListener('DOMContentLoaded', () => {
  const $header = document.querySelector('.js-header');

  if ($header) {
    const header = new Header($header);
    header.mount();
  }
});
