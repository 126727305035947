document.addEventListener("DOMContentLoaded", () => {
  // Fonction pour retirer la classe active des éléments
  function removeActiveClassFromElements(elements) {
    elements.forEach((element) => {
      element.classList.remove("active");
    });
  }

  // Fonction pour mettre à jour le texte du bouton
  function updateButtonText(btn, index, isActive) {
    if (isActive) {
      btn.innerHTML = "Masquer la réponse";
    } else {
      btn.innerHTML = originalButtonTexts[index];
    }
  }

  // Variables pour les éléments de la première partie
  const noticeComponents = document.querySelectorAll(".js-notice");
  const noticeButtons = document.querySelectorAll(".js-notice-button");
  const noticeResponses = document.querySelectorAll(".js-notice-response");
  const noticeResponsesContainer = document.querySelectorAll(".js-notice-response-container");
  const originalButtonTexts = Array.from(noticeButtons).map(
    (btn) => btn.innerHTML
  );

  // Gestion des clics sur les boutons de la première partie
  noticeButtons.forEach((btn, index) => {
    btn.addEventListener("click", () => {
      const isActive = btn.classList.contains("active");

      // Retirer la classe active de tous les éléments
      removeActiveClassFromElements(noticeResponses);
      removeActiveClassFromElements(noticeResponsesContainer);
      removeActiveClassFromElements(noticeButtons);
      removeActiveClassFromElements(noticeComponents);
      noticeResponses.forEach((container) => {
        container.style.maxHeight = null;
      })

      if (!isActive) {
        noticeResponsesContainer[index].classList.add("active");
        noticeResponses[index].style.maxHeight =
          noticeResponses[index].scrollHeight + "px";
        btn.setAttribute("aria-expanded", "true");
        btn.classList.add("active");
        noticeResponses[index].classList.remove("hide");
        setTimeout(() => {
          noticeResponses[index].classList.add("active");
        }, 200);
      } else {
        noticeResponsesContainer[index].classList.remove("active");
        noticeResponses[index].style.maxHeight = null;
        btn.setAttribute("aria-expanded", "false");
        btn.classList.remove("active");
        setTimeout(() => {
          noticeResponses[index].classList.add("hide");
        }, 200);
      }

      // Mettre à jour le texte du bouton
      updateButtonText(btn, index, !isActive);
    });
  });

  // Variables pour les éléments de la seconde partie
  const noticeSecondButton = document.querySelector(
    ".js-notices-second-button"
  );

  if (noticeSecondButton) {
    const noticeSecondContainer = document.querySelector(".js-notices-second");

  // Gestion des clics sur le bouton de la seconde partie
    noticeSecondButton.addEventListener("click", () => {
      noticeSecondButton.classList.toggle("active");
      const isActive = noticeSecondButton.classList.contains("active");

      if (isActive) {
        noticeSecondContainer.style.maxHeight =
          noticeSecondContainer.scrollHeight + "100%";
        noticeSecondContainer.setAttribute("aria-expanded", "true");
        noticeSecondContainer.classList.add("active");
        noticeSecondContainer.classList.remove("hide");
        noticeSecondButton.innerHTML = "Voir moins d'avis";
        noticeSecondContainer.classList.add("active");
      } else {
        noticeSecondContainer.classList.remove("active");
        noticeSecondContainer.style.maxHeight = null;
        noticeSecondContainer.setAttribute("aria-expanded", "false");
        noticeSecondContainer.classList.remove("active");
        noticeSecondButton.innerHTML = "Voir plus d'avis";
        noticeSecondContainer.classList.add("hide");
      }
    });
  }
});
