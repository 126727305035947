import 'core-js/modules/es.array.iterator'; // Make Gmaps Loader work on IE11
import { Loader } from '@googlemaps/js-api-loader/dist/index.umd';
import getIcon from '../../map/icon';

/* global google, GMAP_API_KEY */

document.addEventListener('DOMContentLoaded', () => {
  const $locations = document.querySelectorAll('.js-location');

  function initLocation() {
    $locations.forEach(($location) => {
      const $map = $location.querySelector('.js-location-map');
      const coords = {
        lat: parseFloat($location.getAttribute('data-lat')),
        lng: parseFloat($location.getAttribute('data-lng')),
      };

      const map = new google.maps.Map($map, {
        center: coords,
        zoom: 10,
        disableDefaultUI: true,
        zoomControl: true,
      });

      const marker = new google.maps.Marker({
        position: coords,
        map,
        icon: getIcon('primary'),
      });

      if ($location.getAttribute('data-url')) {
        marker.addListener('click', () => {
          window.open($location.getAttribute('data-url'), '_blank');
        });
      }
    });
  }

  if ($locations.length) {
    new Loader({
      apiKey: GMAP_API_KEY || '',
      version: 'weekly',
    }).load().then(initLocation).catch(() => { });
  }
});
