/**
 * Name: Component
 * Description: Base model for all components
 */

export default class Component {
  static settings = {};

  #element;
  #mounted;
  #settings;

  #listeners = {};

  constructor (element, options = {}) {
    this.#element = element;
    this.#mounted = false;
    this.#settings = {
      ...Component.settings,
      ...options
    };
  }

  get element () {
    return this.#element;
  }

  get mounted () {
    return this.#mounted;
  }

  get settings () {
    return this.#settings;
  }

  getNode (parameter) {
    let element = null;

    if (parameter instanceof Element) {
      element = parameter;
    } else if (typeof parameter === 'string') {
      const testSelector = this.#element.querySelector(parameter);
      element = testSelector ? testSelector : element;
    }

    return element;
  }

  fire (type, data) {
    var listeners = this.#listeners[type] || [];

    listeners.forEach(function (listener) {
      listener(data);
    });
  }

  on (type, handler) {
    if (typeof this.#listeners[type] === 'undefined') {
      this.#listeners[type] = [];
    }

    this.#listeners[type].push(handler);

    return this;
  }

  off (type, handler) {
    var index = this.#listeners[type].indexOf(handler);

    if (index > -1) {
      this.#listeners[type].splice(index, 1);
    }

    return this;
  }

  mount () {
    this.#element.classList.add('is-mounted');
    this.#mounted = true;
    this.fire('mount', this);
  }

  unmount () {
    this.#element.classList.remove('is-mounted');
    this.#mounted = false;
    this.fire('unmount', this);
  }
}
