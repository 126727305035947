/**
 * Name: Dropdown
 */

import Collapsible from "../../../utils/Collapsible";

export default class Dropdown extends Collapsible {

  static settings = {
    control: '.js-dropdown-control',
    target: '.js-dropdown-menu',
    label: '.js-dropdown-label',
    option: '.js-dropdown-option',
    i18n: {
      label_empty: 'Sélectionnez une ou plusieurs valeurs',
      label_count: '%selected% sur %length% %descriptor%',
      singular: 'sélectionnée',
      plural: 'sélectionnées',
      hideSection: 'Masquer',
      showSection: 'Afficher',
    }
  };

  #label;
  #options;

  #handleTargetChange

  constructor (element, options = {}) {
    super(element, {
      ...Dropdown.settings,
      ...options
    });

    this.#label = this.element.querySelector(this.settings.label);
    this.#options = this.target.querySelectorAll(this.settings.option);

    this.#handleTargetChange = this._handleTargetChange.bind(this);
  }

  get selected () {
    return Array.from(this.#options).filter((element) => { return element.checked });
  }

  _handleTargetChange (e) {
    const selected = this.selected;
    let text = this.settings.i18n.label_count;

    if (selected.length === 0) {
      text = this.settings.i18n.label_empty;
    } else if (selected.length === 1) {
      text = text.replace('%descriptor%', this.settings.i18n.singular);
    } else {
      text = text.replace('%descriptor%', this.settings.i18n.plural);
    }

    text = text.replace('%selected%', selected.length);
    text = text.replace('%length%', this.#options.length);


    this.#label.innerHTML = text;
  }

  open () {
    super.open();
    this.target.focus();
  }

  mount () {
    this.target.addEventListener('change', this.#handleTargetChange);
    super.mount();
    this.#handleTargetChange();
    if (this.element.dataset.dropdownLabel) {
      this.target.setAttribute('aria-labelledby', this.element.dataset.dropdownLabel);
    }
  }

  unmount () {
    this.target.removeEventListener('change', this.#handleTargetChange);
    super.unmount();
  }
}
