import 'mdn-polyfills/Object.entries'; // For Swiper : https://github.com/nolimits4web/swiper/pull/4238
import 'mdn-polyfills/String.prototype.repeat'; // For A11Y
import 'core-js/features/number/is-nan'; // For Navigation
import Swiper, { A11y, Navigation } from 'swiper';

Swiper.use([A11y, Navigation]);

document.addEventListener('DOMContentLoaded', () => {
  const $sliders = document.querySelectorAll('.js-card-slider');

  for (let i = 0; i < $sliders.length; i++) {
    const $slider = $sliders[i];
    const $slides = $slider.querySelectorAll('.swiper-slide');

    if ($slides.length > 1) {
      const updateVisibleSlides = (swiper, count) => {
        count = Math.max(count, 1);

        for(let i = 0; i < swiper.slides.length; i++) {
          const $slide = swiper.slides[i];
          if (i >= swiper.activeIndex && i <= (swiper.activeIndex + count - 1)) {
            $slide.classList.remove('is-faded');
          } else {
            $slide.classList.add('is-faded');
          }
        };
      };

      let sliderWidth = $slider.offsetWidth;
      let sliderGap = $slider.dataset.cardSliderGap ? parseInt($slider.dataset.cardSliderGap, 10): 60;
      let slideWidth = $slides[0].offsetWidth;
      let visibleSlidesCount = (sliderWidth - sliderGap) / (slideWidth + sliderGap);

      const slider = new Swiper($slider.querySelector('.swiper-container'), {
        a11y: {
          prevSlideMessage: 'Précédent',
          nextSlideMessage: 'Suivant',
        },
        navigation: {
          nextEl: $slider.querySelector('.swiper-button-next'),
          prevEl: $slider.querySelector('.swiper-button-prev'),
        },
        slidesPerView: 'auto',
        spaceBetween: 10,
        breakpoints: {
          510: {
            spaceBetween: 20,
          },
          768: {
            spaceBetween: sliderGap,
          },
        },
      });

      slider.on('slideChangeTransitionStart', (swiper) => {
        updateVisibleSlides(slider, visibleSlidesCount);
      });

      slider.on('resize', (swiper) => {
        sliderWidth = $slider.offsetWidth;
        sliderGap = swiper.params.spaceBetween;
        slideWidth = $slides[0].offsetWidth;
        visibleSlidesCount = (sliderWidth - sliderGap) / (slideWidth + sliderGap);
        updateVisibleSlides(swiper, visibleSlidesCount);
      });

      updateVisibleSlides(slider, visibleSlidesCount);
    } else {
      $slider.classList.add('no-slider');
    }
  }
});
