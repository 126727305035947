import { createPlaceholder, createYoutubePlaceholder, createGMapPlaceholder } from './placeholders';

export default class ContextualDisplayer {
  constructor($holder, service, callback = null) {
    this.$holder = $holder;
    this.service = service;
    this.callback = callback;

    this.displayPlaceholder();

    document.addEventListener('cookies:updateChoice', e => {
      if (e.detail[this.service]) {
        this.displayElement();
      } else {
        this.displayPlaceholder();
      }
    });
  }

  displayPlaceholder() {
    let html = '';
    switch (this.service) {
      case 'youtube':
        html = createYoutubePlaceholder().outerHTML;
        break;
      case 'gmaps':
        html = createGMapPlaceholder().outerHTML;
        break;
      default:
        html = createPlaceholder(this.service, this.service).outerHTML;
        break;
    }

    this.$holder.innerHTML = html;

    if (this.callback) {
      this.callback(false);
    }
  }

  displayElement() {
    let html = '';

    switch (this.service) {
      case 'youtube':
        html = `
          ${this.$holder.getAttribute('data-src')}
        `;
        break;
      case 'gmaps':
        html = '';
        this.$holder.setAttribute('hidden', '');
        break;
      default:
        break;
    }

    this.$holder.innerHTML = html;

    if (this.callback) {
      this.callback(true);
    }
  }
}

(window._axcb = window._axcb || []).push(sdk => {
  sdk.on('cookies:complete', choices => {
    const event = new CustomEvent('cookies:updateChoice', {
      detail: choices,
    });
    document.dispatchEvent(event);
  });
});

const $holders = document.querySelectorAll('.js-axeptio-contextual-holder');

if ($holders.length) {
  $holders.forEach($holder => {
    const displayer = new ContextualDisplayer($holder, $holder.getAttribute('data-service'));
  });
}

const $openers = document.querySelectorAll('.js-axeptio-opener');

if ($openers.length) {
  $openers.forEach($opener => {
    $opener.addEventListener('click', e => {
      openAxeptioCookies();
    });
  });
}
