import 'mdn-polyfills/Object.entries'; // For Swiper : https://github.com/nolimits4web/swiper/pull/4238
import 'mdn-polyfills/String.prototype.repeat'; // For A11Y
import 'mdn-polyfills/CustomEvent'; // For Custom effect's DOM7 usage
import Swiper, { A11y, Autoplay, Pagination } from 'swiper';
import clamp from '../../utils/clamp';
import easingsFunctions from '../../utils/easings';

Swiper.use([A11y, Autoplay, Pagination]);

document.addEventListener('DOMContentLoaded', () => {
  const $sliders = document.querySelectorAll('.js-hero');

  // CSS, animations and transitions, related variables
  const slideDuration = 7500; // ms
  const transitionDuration = 1000; // ms
  const { easeInSine } = easingsFunctions;
  const pictureScale = { from: 1, to: 1.1 };

  /**
   * Base of custom effect
   * Inspired from FadeEffect : https://github.com/nolimits4web/swiper/blob/master/src/components/effect-fade/effect-fade.js
   */
  const customEffect = {
    setTranslate(swiper) {
      const { slides } = swiper;

      for (let i = 0; i < slides.length; i += 1) {
        // eq is a DOM7 (Swiper's dependency) function
        const $slideEl = swiper.slides.eq(i);
        const offset = $slideEl[0].swiperSlideOffset;
        let tx = -offset;
        if (!swiper.params.virtualTranslate) tx -= swiper.translate;
        let ty = 0;
        if (!swiper.isHorizontal()) {
          ty = tx;
          tx = 0;
        }
        // transform is a DOM7 (Swiper's dependency) function
        $slideEl.transform(`translate3d(${tx}px, ${ty}px, 0px)`);
      }
    },
    setTransition(swiper, duration) {
      const { $wrapperEl } = swiper;

      if (swiper.params.virtualTranslate && duration !== 0) {
        let eventTriggered = false;
        setTimeout(() => {
          if (eventTriggered) return;
          if (!swiper || swiper.destroyed) return;
          eventTriggered = true;
          swiper.animating = false;
          const triggerEvents = ['webkitTransitionEnd', 'transitionend'];
          for (let i = 0; i < triggerEvents.length; i += 1) {
            // trigger is a DOM7 (Swiper's dependency) function
            $wrapperEl.trigger(triggerEvents[i]);
          }
        }, duration);
      }
    },
  };

  // Loop on sliders
  for (let i = 0; i < $sliders.length; i++) {
    const $slider = $sliders[i];
    const $details = $slider.querySelector('.js-hero-details');
    const $detailsContent = $slider.querySelector('.js-hero-details-content');
    let heroSlider;

    if ($slider.querySelectorAll('.swiper-slide').length > 1) {
      let firstSlideDone = false;

      // Custom transition
      let lastSlideStart = null;

      const animateTransition = (swiper) => {
        const newSlideStart = Date.now();
        const x = clamp((newSlideStart - lastSlideStart) / slideDuration, 0, 1);
        const scale = pictureScale.from + (easeInSine(x) * (pictureScale.to - pictureScale.from));

        const animateDetails = () => {
          $detailsContent.classList.remove('is-out');
          $detailsContent.innerHTML = swiper.slides[swiper.activeIndex].querySelector('.js-hero-slide-details').innerHTML;
          $detailsContent.classList.add('is-in');

          const focusableElements = $detailsContent.querySelectorAll('[tabindex="-1"]');
          for (let j = 0; j < focusableElements.length; j += 1) {
            focusableElements[j].removeAttribute('tabindex');
          }
        };

        lastSlideStart = newSlideStart;

        if (typeof swiper.previousIndex !== 'undefined') {
          const $previousSlide = swiper.slides[swiper.previousIndex];
          const $picture = $previousSlide.querySelector('.c-hero__picture');

          $previousSlide.classList.add('is-out');
          $picture.style.animation = 'none';
          $picture.style.transform = `scale(${scale})`;
          $detailsContent.classList.add('is-out');

          setTimeout(animateDetails, transitionDuration / 2);
        } else {
          animateDetails();
        }
      };

      // Progress bar
      const $progressElement = document.createElement('div');
      let rafID = null;
      let progressStart = null;
      $progressElement.classList.add('c-hero__progress-bar');

      const updateProgressBar = () => {
        if (heroSlider.autoplay.running && !heroSlider.autoplay.paused) {
          const delta = Date.now() - progressStart;

          if (delta >= transitionDuration) {
            const scaleX = Math.max(1 - ((delta - transitionDuration) / (slideDuration - transitionDuration)), 0);
            $progressElement.style.transform = `scale(${scaleX}, 1)`;
          } else if (delta >= (transitionDuration / 2)) {
            const scaleY = clamp((delta - (transitionDuration / 2)) / (transitionDuration / 2), 0, 1);
            $progressElement.style.transform = `scale(1, ${easeInSine(scaleY)})`;
          } else {
            $progressElement.style.transform = 'scale(0, 0)';
          }
        }

        rafID = requestAnimationFrame(updateProgressBar);
      };

      const startProgressBar = () => {
        cancelAnimationFrame(rafID);
        progressStart = Date.now();
        rafID = requestAnimationFrame(updateProgressBar);
      };

      // Init slider
      heroSlider = new Swiper($slider.querySelector('.swiper-container'), {
        a11y: {
          paginationBulletMessage: 'Atteindre la slide {{index}}',
        },
        autoplay: {
          delay: slideDuration,
          disableOnInteraction: false,
          waitForTransition: false,
        },
        effect: 'custom',
        pagination: {
          el: $slider.querySelector('.swiper-pagination'),
          type: 'bullets',
          clickable: true,
        },
        speed: transitionDuration,
        virtualTranslate: true, // Required for custom effect
        watchSlidesProgress: true, // Required for custom effect

        // See : https://github.com/nolimits4web/swiper/issues/3752
        shortSwipes: false,
        longSwipesRatio: 0.1,
        longSwipesMs: 100,

        on: {
          beforeInit: (swiper) => {
            // There is no way to track everytime the autoplay timeout is cleared/setted.
            // So we override the function.
            const originalRun = swiper.autoplay.run;
            swiper.autoplay.run = () => {
              startProgressBar();
              originalRun();
            };
          },
          init: (swiper) => {
            $details.appendChild($progressElement);
            swiper.slides[swiper.activeIndex].classList.add('swiper-slide-first-run');
            animateTransition(swiper);
          },
          setTranslate(swiper) {
            if (swiper.params.effect !== 'custom') return;
            customEffect.setTranslate(swiper);
          },
          setTransition(swiper, duration) {
            if (swiper.params.effect !== 'custom') return;
            customEffect.setTransition(swiper, duration);
          },
          slideChangeTransitionStart: (swiper) => {
            if (!firstSlideDone) {
              swiper.slides[swiper.previousIndex].classList.remove('swiper-slide-first-run');
              firstSlideDone = true;
            }
            animateTransition(swiper);
          },
          slideChangeTransitionEnd: (swiper) => {
            const $previousSlide = swiper.slides[swiper.previousIndex];
            const $picture = $previousSlide.querySelector('.c-hero__picture');

            $previousSlide.classList.remove('is-out');
            $picture.style.animation = '';
            $picture.style.transform = '';
          },
          // Custom looping
          // As there is no way to prevent creation of duplicate slides with loop parameter.
          touchEnd: (swiper) => {
            if (swiper.activeIndex === 0 && swiper.swipeDirection === 'prev') {
              swiper.slideTo(swiper.slides.length - 1);
            }

            if (swiper.activeIndex === swiper.slides.length - 1 && swiper.swipeDirection === 'next') {
              swiper.slideTo(0);
            }
          },
        },
      });
    } else {
      const $slide = $slider.querySelector('.swiper-slide');

      $slider.classList.add('no-slider');
      $slide.classList.add('swiper-slide-active');
      $detailsContent.innerHTML = $slide.querySelector('.js-hero-slide-details').innerHTML;
    }
  }
});
