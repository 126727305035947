document.addEventListener('DOMContentLoaded', () => {
  const $formConditionals = document.querySelectorAll('.js-form-conditional');

  const update = ($element, state) => {
    if (state) {
      $element.removeAttribute('hidden');
    } else {
      $element.setAttribute('hidden', '');
    }
  }

  for (let i = 0; i < $formConditionals.length; i++) {
    const $formConditional = $formConditionals[i];
    const condition = JSON.parse($formConditional.dataset.formConditionalTrigger);

    const $trigger = document.getElementById(condition.id);

    if ($trigger) {
      $trigger.addEventListener('change', (e) => {
        update($formConditional, e.target.checked);
      });

      update($formConditional, $trigger.checked);
    }
  };

});
