import { throttle } from 'throttle-debounce';

export default class ProgressSpy {
  constructor($element, start, end) {
    const _ = this;

    // Elements
    _.$element = $element;

    // Variables
    _.start = start;
    _.end = end;

    // Throttled functions
    _.throttledWatchScroll = throttle(50, _.watchScroll.bind(_));
  }

  mount() {
    const _ = this;

    _.updateProgression();
    window.addEventListener('scroll', _.throttledWatchScroll);
  }

  dismount() {
    const _ = this;

    window.removeEventListener('scroll', _.throttledWatchScroll);
  }

  watchScroll() {
    const _ = this;

    _.updateProgression();
  }

  updateProgression() {
    const _ = this;
    const percent = Math.round((window.pageYOffset * 100) / (_.end - window.innerHeight));

    _.$element.style.width = `${Math.max(0, Math.min(percent, 100))}%`;
  }

  update(start, end) {
    const _ = this;

    _.start = start;
    _.end = end;

    _.updateProgression();
  }
}
