import Dropdown from './Dropdown';

document.addEventListener('DOMContentLoaded', () => {
  const $dropdowns = document.querySelectorAll('.js-dropdown');

  for(let i = 0; i < $dropdowns.length; i++) {
    const $dropdown = $dropdowns[i];
    const dropdown = new Dropdown($dropdown);
    dropdown.mount();
  }
});
